<template >
    <div class="logList">
        <div class="logButton">
            <div class="export">
                <el-button type="primary" @click="dialogVisible = true">添加同事</el-button>
            </div>
            <div class="search">
                <!-- 搜索题目 -->
                <div class="searchContent">
                    <el-input placeholder="请输入同事姓名或手机号" v-model="searchContent" class="input-with-select"></el-input>
                </div> 
                <el-button type="primary" @click="handleSearchColleague">搜索</el-button>
            </div>
        </div>
        <!-- 题目列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="colleagueList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                      type="index"
                      label="序号"
                      width="100">
                    </el-table-column>
                    <!-- 头像 -->
                    <el-table-column
                      label="头像"
                      width="200">
                        <template slot-scope="scope">
                            <img class="wx_img" :src="scope.row.wx_img">
                        </template>
                    </el-table-column>
                    <!--姓名  -->
                    <el-table-column
                      prop="name"
                      label="用户名">
                    </el-table-column>
                    <!--手机号 -->
                    <el-table-column
                      prop="tel"
                      label="教职工号">
                    </el-table-column>
                    <!-- 操作-->
                    <el-table-column
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="deleteColleague(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- 题目分页 -->
            <el-pagination
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="colleagueListTotal">
            </el-pagination>
        </el-card>
            <el-dialog
            title="添加同事"
            :visible.sync="dialogVisible"
            width="30%">
                <span  class="dialog-span">
                    <el-select
                        v-model="colleague_id"
                        filterable
                        remote
                        clearable
                        placeholder="请输入同事姓名！"
                        :remote-method="searchColleague">
                        <el-option
                            v-for="item in searchColleagueList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </span>
            <span
                slot="footer"
                class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  
</template>

<script>
    // 引入试题列表js
    import logList from "@/assets/js/teacher/other/colleague.js";
    export default {
     ...logList
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/teacher/other/colleague.scss";
</style>